.accountContainer {
  font-family: "Inter", sans-serif;
  padding: 15px;
  margin: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
}