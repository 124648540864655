.home {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  background-size: cover;
}

.homeOutlet {
  width: 100%;
}

.homeButton {
  padding: 12px;
  font-size: 16px;
  background-color: #0538af;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.homeButton:hover {
  background-color: #0056b3;
}

.homeMenu {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 80px;
  justify-content: space-between;
  display: flex;
  background-image: linear-gradient(45deg, #f5f5f5 0%, #fff 90%);
  background-color: rgb(247, 247, 247);
  color: #0538af;
}

.homeMenuContent {
  width: 100%;
  display: flex;
}

.homeMenuImg:hover {
  cursor: pointer;
}

.homeMenuTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 35px;
}

.homeMenuTitle a {
  text-decoration: none;
  color: inherit;
}

.homeMenuTitle h2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeMenuImg img {
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.mobileHomeMenu {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  overflow: clip;
  position: absolute;
  height: 80px;
}

.mobileHomeMenuItems {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  overflow: clip;
  background: white;
  height: 80px;
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 0.5s; /* Firefox < 16 */
        -ms-animation: fadein 0.5s; /* Internet Explorer */
         -o-animation: fadein 0.5s; /* Opera < 12.1 */
            animation: fadein 0.5s;
}

.mobileHomeMenuItem {
  display: flex;
  justify-content: center;
  color: #0538af;
  text-decoration: underline;
  padding-left: 20px;
}

.mobileHomeMenuButton {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0538af;
  text-decoration: none;
  padding: 20px;
  background: unset;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 20px;
}

.homeMenuItems {
  display: none;
}

.homeMenuItem {
  display: flex;
  justify-content: center;
  color: #0538af;
  text-decoration: none;
  padding-left: 30px;
}

.homeMenuItem:hover {
  text-decoration: underline;
}

.homeMenuDashboard {
  display: none;
  padding-right: 35px;
}

.homeMenuDashboard a {
  color: #FF7676;
  padding-left: 10px;
}

@media (min-width: 600px) {
  .homeMenuContent {
    width: auto;
    display: flex;
  }

  .mobileHomeMenu {
    display: none;
  }

  .homeMenuItems {
    display: flex;
    justify-content: start;
    align-items: center;
    overflow: clip;
    padding-left: 10px;
  }

  .homeMenuDashboard {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 10px;
  }
}