/* EDIT PROPS */

.editModeHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  width: 100%;
  z-index: 2;
}

.editModeHeader button {
  color: rgb(255, 65, 65) !important;
}

.editModeHeader button:hover {
  border: 2px solid rgb(255, 65, 65) !important;
}

.preEditSelector:hover {
  cursor: pointer;
  border: 2px dotted rgb(153, 144, 238);
  margin: -2px;
}

.editActive {
  cursor: pointer;
  border: 2px dotted rgb(153, 144, 238);
  margin-left: -6px;
  margin-right: -6px;
}

.editEmpty {
  color: rgb(143, 143, 143);
}

.editSelector input, textarea {
  all:inherit;
  background: transparent;
  border: none;
  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  text-align: inherit;
  width:100%;
  height: 100%;
}

.editTextArea {
  resize: none;
  overflow-x: clip;
}

.editCloseButton {
  border: none !important;
  display: flex !important;
  justify-content: start !important;
}

.editButton {
  border: none !important;
  display: flex !important;
  justify-content: start !important;
}

.editButton:hover, .editCloseButton:hover {
  color: rgb(153, 144, 238) !important;
}

.editButton:focus, .editCloseButton:focus {
  color: rgb(153, 144, 238) !important;
}

.editButtonGroup {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.editBreak {
  flex-basis: 100%;
  height: 0;
}

.editImage {
  display: flex;
}

.editImageAddButton {
  position: absolute;
}

.editImageRemoveButton {
  position: absolute;
  margin-top: 50px !important;
  display: flex !important;
}