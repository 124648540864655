html {
  scroll-behavior: smooth;
}

.webpageRoot {
  /* Ensure this is set on the root element for webpage generator */
  margin: 0;
}

.webpage {
  font-family: "Inter", sans-serif;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

.topHeader {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 80px;
  justify-content: center;
  display: flex;
  background-color: rgb(247, 247, 247);
  color: black;
}

.topHeaderContent {
  width: auto;
  display: flex;
}

.topHeaderImg:hover {
  cursor: pointer;
}

.topHeaderTitle a {
  text-decoration: none;
  color: inherit;
}

.topHeaderImg img {
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.topHeaderTitle {
  display: flex;
  justify-content: start;
  align-items: center;
  overflow: clip;
  padding-left: 10px;
}

.topHeaderMenu {
  display: none;
}

.topHeaderMenuMobile {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: end;
  padding-right: 10px;
}

.topHeaderMenuItem {
  width: 100px;
  display: flex;
  justify-content: center;
}

.topHeaderMenuItem a {
  text-decoration: none;
}

.topHeaderMenuItem a:hover {
  text-decoration: underline;
}

/* TODO: Adjust this styling */
.topHeaderContact {
  display: none;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 100%;
}

.topContainer {
  overflow: clip;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  justify-content: center;
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 3s; /* Firefox < 16 */
        -ms-animation: fadein 3s; /* Internet Explorer */
         -o-animation: fadein 3s; /* Opera < 12.1 */
            animation: fadein 3s;
}

.topTitle {
  text-align: left;
  padding: 6px;
}

.topTitle h1 {
  font-weight: 800 !important;
  font-size: 60px !important;
  line-height: 55px !important;
  letter-spacing: -2px;
  margin: 0px;
  text-align: center;
}

.topDesc h2 {
  font-size: 2rem !important;
  text-align: center;
}

.topDesc {
  max-width: 1024px;
  padding: 15px;
  -webkit-animation: fadein 6s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 6s; /* Firefox < 16 */
        -ms-animation: fadein 6s; /* Internet Explorer */
         -o-animation: fadein 6s; /* Opera < 12.1 */
            animation: fadein 6s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.webpage h1 {
  font-weight: 700;
  font-size: 48px;
  line-height: 48px;
  letter-spacing: -2px;
}

.webpage h3 {
  font-size: 1.25rem;
  text-align: left;
  text-transform: uppercase;
}

.webpage h2 {
  font-size: 2rem;
  font-weight: 600;
}

.webpage p {
  color: black;
}

.topHeader h2 {
  font-size: 1.2rem !important;
  padding-left: 20px;
  margin-right: 20px;
}

.top {
  width: 100%; 
  /* to account for the top header */
  height: 92vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.aboutMe {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.aboutMeContent {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  flex-direction: column;
  padding-top: 40px;
}

.aboutMeContent p, h1{
  padding: 10px;
  width: auto;
  text-align: center;
}

.mid {
  width: 100%; 
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 100px;
}

.mid h1 {
  text-align: left;
  padding: 6px!important;
}

.midItems {
  display: flex;
  flex-direction: column;
  background: transparent;
  overflow-x: clip;
  width: 100%;
}

.midItems::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.midItem {
  display: flex;
  width: 100%;
  justify-content: center;
}

.midItem img {
  border-radius: 0px;
}

.midItem .renderItemText {
  align-content: center;
  padding: 30px;
  width: 100%;
  text-align: left;
}

.renderItem {
  display: flex;
  justify-content: center;
}

.leftItem {
  flex-direction: column;
}

.rightItem {
  flex-direction: column-reverse;
}

.renderItem img{
  width: 100%;
  height: auto;
}

.renderItemText {
  text-align: center;
  min-width: 200px;
  word-wrap: break-word;
}

.renderItemIcon {
  padding-top: 25px;
  font-size: 2rem;
}

.otherSection {
  width: 100%; 
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;
}

.otherSection h1 {
  width: 100%;
  text-align: left;
  padding: 6px!important;
}

.otherItems {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.otherItem {
  padding: 6px;
  background-color: rgba(247, 247, 247);
  overflow-x: clip;
  border-radius: 6px;
  color: black;
  /* Lite drop shadow */
  box-shadow: 0px 0px 0px 1px #0E3F7E08, 0px 1px 1px -0.5px #2A334508, 0px 3px 3px -1.5px #2A334608, 0px 6px 6px -3px #2A334608, 0px 12px 12px -6px #0E3F7E08, 0px 24px 24px -12px #0E3F7E10;
}

.otherItem .renderItemText {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.otherItem .renderItemText p,h2 {
  margin: 0px;
  width: 100%;
}

.otherItem .renderItemIcon {
  display: flex;
  padding-top: 0px;
  font-size: 2rem;
  padding-right: 10px;
}

.otherContent {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer p {
  font-size: 0.8rem;
}

.footer a {
  cursor: pointer;
  text-decoration: none;
  font-size: 0.8rem;
  color: black;
}

.footer a:hover {
  text-decoration: underline;
}

.footerContent {
  display: flex;
  flex-direction: column-reverse;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-left: 10px;
}

.footerImg {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footerImg img {
  max-width: 200px;
}

.footerContact {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.footerContact p {
  text-align: left;
}

.footerSitemap {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.footerSitemap a {
  margin-top: 12.8px;
  margin-bottom: 12.8px;
}

.footerBottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.coreButton {
  padding-bottom: 2rem;
  text-align: center;
  display: flex;
  align-items: end;
}

.toTopButton {
  display: none;
}

.toTopButton a {
  display: none;
}

.webpage .mainButton {
  border-radius: 5px;
  align-items: center;
  border: 1px solid rgb(189, 204, 224);
  box-sizing: border-box;
  color: rgb(189, 204, 224);
  cursor: pointer;
  display: inline-flex;
  fill: rgb(189, 204, 224);
  font-size: 16px;
  font-weight: 700;
  height: 48px;
  justify-content: center;
  line-height: 24px;
  min-width: 140px;
  outline: 0;
  padding: 0 17px;
  text-align: center;
  text-decoration: none;
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  background-color: transparent;
}

.webpage .mainButton:focus {
  color: rgb(255, 255, 255);
}

.webpage .mainButton:hover {
  border-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  fill: rgb(255, 255, 255);
  background-color: rgba(255, 255, 255, 0.3)!important;
}

.webpage .mainButton:active {
  border-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  fill: rgb(255, 255, 255);
}

@media (min-width: 1024px) {
  .webpage .mainButton {
    font-size: 30px;
    min-width: 170px;
  }

  .webpage h1 {
    font-weight: 800;
    font-size: 60px;
    line-height: 55px;
    letter-spacing: -2px;
    overflow: unset;
  }

  .topHeader {
    height: 80px;
  }

  .topHeaderContent {
    flex-direction: row;
    width: 1024px;
  }

  .topHeaderTitle {
    width: 100%;
  }

  .topHeaderMenu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
  }

  .topHeaderMenuMobile {
    display: none;
  }

  .topHeaderContact {
    align-items: end;
    width: 100%;
  }

  .top {
    align-items: center;
  }

  .topTitle {
    width: 1024px;
  }

  .topTitle h1 {
    font-size: 183px !important;
    line-height: 150px !important;
    letter-spacing: -9px;
    width: 100%;
  }

  .topDesc h2 {
    font-size: 3rem !important;
  }

  .topContainer {
    overflow: unset;
  }

  .aboutMeContent {
    width: 1024px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-direction: row;
    padding-top: 0px;
  }

  .aboutMeContent p, h1{
    text-align: left;
    width: 50%;
  }

  .mid {
    align-items: center;
    padding-bottom: 0;
  }

  .midTitle h1 {
    width: 1024px;
  }

  .midItems {
    flex-direction: row;
    width: 1024px;
    align-items: start;
    flex-wrap: wrap;
    justify-content: center;
  }

  .midItem {
    min-width: 500px;
  }

  .midItem img {
    border-radius: 20px;
    /* Lite drop shadow */
    box-shadow: 0px 0px 0px 1px #0E3F7E08, 0px 1px 1px -0.5px #2A334508, 0px 3px 3px -1.5px #2A334608, 0px 6px 6px -3px #2A334608, 0px 12px 12px -6px #0E3F7E08, 0px 24px 24px -12px #0E3F7E10;
  }

  .renderItem {
    flex-direction: row;
  }

  .midItems .renderItem {
    padding-bottom: 200px;
  }

  .otherTitle {
    width: 1024px;
  }

  .otherContent {
    width: 1024px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .otherItem {
    width: 100%;
  }

  .otherItem .renderItemText {
    flex-direction: row;
    align-items: center;
  }

  .footerContent {
    width: 1024px;
    flex-direction: row;
    margin-left: 0px;
  }

  .footerImg {
    width: 50%;
  }
  
  .footerContact, .footerSitemap {
    width: 25%;
  }

  .toTopButton {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
  }

  .toTopButton a {
    display: flex;
    right: 0;
    position: relative;
    padding-right: 10px;
  }
}