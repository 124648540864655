.settingsEditBackground img {
    max-width: 300px;
}

.sideBar {
    font-family: "Inter", sans-serif;
    z-index: 4;
    display: block;
    width: 100%;
    position: fixed;
    top: 0;
    background-color: rgb(255, 255, 255);
    border-width: 2px;
    border-color: black;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}
  
.sideBarMobileButton {
    display: block;
    position: fixed;
    top: 90px;
    z-index: 5;
    border: none;
    background-color: unset;
    color: #0538af;
    font-size: 15px;
    padding: 10px;
    border-radius: 5px;
    margin: 10px;
}
  
.sideBarMobileButton:hover {
    cursor: pointer;
    color: #0056b3;
    opacity: 0.9;
}
  
.sideBarDash {
    flex-shrink: 0;
    border-width: 0px 0px medium;
    border-style: solid solid none;
    border-color: rgba(0, 0, 0, 0.12) rgba(0, 0, 0, 0.12) currentcolor;
    background-color: transparent;
    background-image: linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.5), rgba(52, 71, 103, 0)) !important;
    height: 0.0625rem;
    margin: 1rem 0px;
    opacity: 0.25;
}
  
.sideBarTitle {
    padding-top: 30px;
    text-align: center;
}
  
.sideBarOptions {
    display: flex;
    flex-direction: column;
    padding: 15px;
    position: sticky;
    top: 0px
}
  
.sideBarOptions a{
    display: flex;
    text-decoration: none;
    margin-top: 10px;
    padding: 5px;
    color: rgb(117, 117, 117);
}
  
.sideBarOptions a:hover{
    cursor: pointer;
    color: rgb(66, 68, 77);
}
  
.sideBarOptions svg{
    padding-right: 10px;
}

@media (min-width: 600px) {
    .sideBar {
        display: block;
        width: 300px;
        min-height: 100vh;
        position: fixed;
        top: 80px;
        background-color: rgb(255, 255, 255);
    }
}