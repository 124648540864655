.login-container {
  font-family: "Inter", sans-serif;
  text-align: center;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

.login-box {
  width: 350px;
  padding: 40px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.login-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.input-field {
  margin-bottom: 20px;
  text-align: left;
}

.login-container label {
  font-size: 14px;
  color: #333;
  display: block;
  margin-bottom: 5px;
}

.login-container input[type='email'],
.login-container input[type='password'] {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
}

.login-container button {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.login-container button:hover {
  background-color: #0056b3!important;
}

.signup-link {
  margin-top: 20px;
  font-size: 14px;
}

.signup-link a {
  color: #007bff;
  text-decoration: none;
}