.webpageLP {
  font-family: "Inter", sans-serif;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

.webpageLP a {
  color: #FFF;
}

.topHeaderLP {
  width: 100%;
  display: flex;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 60px;
}

.topHeaderImgLP:hover {
  cursor: pointer;
}

.topHeaderImgLP img {
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.topHeaderContainerLP {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 30px;
  overflow: hidden;
}

.topContainerLP {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  align-items: center;
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 3s; /* Firefox < 16 */
        -ms-animation: fadein 3s; /* Internet Explorer */
         -o-animation: fadein 3s; /* Opera < 12.1 */
            animation: fadein 3s;
}

.topTitleLP {
  text-align: center;
}

.topDescLP {
  -webkit-animation: fadein 6s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 6s; /* Firefox < 16 */
        -ms-animation: fadein 6s; /* Internet Explorer */
         -o-animation: fadein 6s; /* Opera < 12.1 */
            animation: fadein 6s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.topTitleLP h1 {
  font-weight: 800 !important;
  font-size: 60px !important;
  line-height: 55px !important;
  letter-spacing: -2px;
  margin: 0px;
}

.topDescLP h2 {
  font-size: 2rem !important;
}

.topLP {
  width: 100%; 
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coreButtonLP {
  padding-bottom: 2rem;
  text-align: center;
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.mainButtonLP {
  border-radius: 5px;
  align-items: center;
  border: 1px solid rgb(189, 204, 224);
  box-sizing: border-box;
  color: rgb(189, 204, 224);
  cursor: pointer;
  display: inline-flex;
  fill: rgb(189, 204, 224);
  font-size: 16px;
  font-weight: 700;
  height: 48px;
  justify-content: center;
  line-height: 24px;
  min-width: 140px;
  outline: 0;
  padding: 0 17px;
  text-align: center;
  text-decoration: none;
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  background-color: transparent;
}

.mainButtonLP:focus {
  color: rgb(255, 255, 255);
}

.mainButtonLP:hover {
  border-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  fill: rgb(255, 255, 255);
  background-color: rgba(255, 255, 255, 0.3)!important;
}

.mainButtonLP:active {
  border-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  fill: rgb(255, 255, 255);
}

@media (min-width: 1024px) {
  .mainButtonLP {
    font-size: 30px;
    min-width: 170px;
  }

  .topTitleLP h1 {
    font-size: 183px !important;
    line-height: 150px !important;
    letter-spacing: -9px;
  }

  .topDescLP h2 {
    font-size: 3rem !important;
  }

  .topContainerLP {
    max-width: 600px;
    overflow: unset;
  }
}