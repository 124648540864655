.adminContainer {
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;
  padding: 15px;
  margin: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
}

.pageItem {
  width: 100%;
}

.mobilePreview {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.mobilePreviewTitle {
  color: #FF7676;
}

.templatePreview {
  width: 100%;
  height: 80vh;
  border: 1px solid #FF7676;
}

@media (min-width: 600px) {
  .adminContainer {
    flex-direction: row;
  }

  .templatePreview {
    width: 25vw;
  }
}