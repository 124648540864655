.accountContainer {
  font-family: "Inter", sans-serif;
  padding: 15px;
  margin: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
}

.accountContainer h1 {
  padding-left: 0px;
}

.accountInfoCard {
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  /* Lite drop shadow */
  box-shadow: 0px 0px 0px 1px #0E3F7E08, 0px 1px 1px -0.5px #2A334508, 0px 3px 3px -1.5px #2A334608, 0px 6px 6px -3px #2A334608, 0px 12px 12px -6px #0E3F7E08, 0px 24px 24px -12px #0E3F7E10;
}

.accountInfoGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accountInfoLine {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.accountInfoLineLabel {
  font-weight: 600;
  padding-right: 10px;
  display: flex;
  align-items: center;
}

.accountInfoImg img {
  height: 200px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.accountInputColor{
  margin-right: 10px;
}

.webpageVersionText {
  font-style: italic;
  font-size: 12px;
  color: #6c757d;
}